import http from "./http";
import { getJwt } from "./auth";
import { toast } from "react-toastify";

export const getAPI = async (path, values) => {
    if (values) {
        return getJwt()
            .then(() => http.get(`get/${path}.php`, { params: values }))
            .catch((e) => {
                if (e.response.data.error.message === "Token is not valid!") window.location.reload();
                else {
                    console.log(e.response);
                    toast.error("Something went wrong!");
                    return { data: { error: e } };
                }
            });
    } else
        return getJwt()
            .then(() => http.get(`get/${path}.php`))
            .catch((e) => {
                if (e.response.data.error.message === "Token is not valid!") window.location.reload();
                else {
                    console.log(e.response);
                    toast.error("Something went wrong!");
                    return { data: { error: e } };
                }
            });
};

export const getWorkspaces = async () => {
    try {
        const { data } = await getAPI("workspaces");
        !data.success && !data.error && !data.empty && console.log(data);
        return data;
    } catch (error) {
        console.error(error);
    }
};

export const summary = async (params) => {
    try {
        const { data } = await getAPI("summary", params);
        !data.success && !data.error && !data.empty && console.log(data);
        return data;
    } catch (error) {
        console.error(error);
    }
};

export const getComments = async (params) => {
    try {
        console.log(params, "params");
        const { data } = await getAPI("comments", params);
        !data.success && !data.error && console.error(data);
        return data;
    } catch (error) {
        console.error(error);
    }
};

export const getExport = async (params) => {
    try {
        const { data } = await getAPI("export", params);
        !data.success && !data.error && console.error(data);
        return data;
    } catch (error) {
        console.error(error);
    }
};

export const getMonthlyReport = async (month, year) => {
    try {
        const params = {
            month: String(month).padStart(2, "0"),
            year: String(year),
        };

        console.log("Requesting data with params:", params);

        const response = await getAPI("monthlyDashboard", params);

        // If response is undefined or null, handle it
        if (!response || !response.data) {
            console.error("Invalid response from API:", response);
            return { error: "Failed to connect to API" };
        }

        const { data } = response;

        if (!data.success && !data.error && !data.empty) {
            console.log("Unexpected data format:", data);
        }

        return data;
    } catch (error) {
        console.error("Error in getMonthlyReport:", error);
        return { error: error.message || "An unknown error occurred" };
    }
};

export const getExportCsv = async (brand, month, year) => {
    try {
        console.log("Original brand name:", brand);
        
        // Convert month and year to the expected date format
        const startDate = new Date(year, month - 1, 1);
        const endDate = new Date(year, month, 0);
        
        const formatDate = (date) => {
            const mm = String(date.getMonth() + 1).padStart(2, "0");
            const dd = String(date.getDate()).padStart(2, "0");
            const yyyy = date.getFullYear();
            return `${mm}/${dd}/${yyyy}`;
        };

        const dateRange = `${formatDate(startDate)}-${formatDate(endDate)}`;
        
        // Map the frontend platform names to the backend workspace keys
        const platformToWorkspaceMap = {
            'cirro_web': 'cirro-web',
            'cirro_app': 'cirro-app',
            'de_web_texas': 'de-texas',
            'de_web_east': 'de-east',
            'de_app': 'de-texas-app',
            'de_east_app': 'de-east-app',
            'de_web_canada': 'de-web-canada',
            'de_web_usa': 'de-web-usa',
            'dp_web': 'dp-web',
            'dp_app': 'dp-app',
            'gme_web': 'gme-web',
            'gme_app': 'gme-app',
            'reliant_web': 'reliant-web',
            'reliant_app': 'reliant-app',
            'stream_web': 'stream-web',
            'xoom_web': 'xoom-web',
            'nrg_app': 'nrg-app',
            'picknrg_web': 'pickNRG'
        };
        
        // Use the mapping or fall back to the standard hyphen conversion
        const formattedBrand = platformToWorkspaceMap[brand] || brand.replace(/_/g, "-");
        
        console.log("Mapped brand name:", formattedBrand);

        const response = await getJwt().then(() => {
            return http.get("get/exportmonthly.php", {
                params: {
                    date: dateRange,
                    brand: formattedBrand,
                },
                responseType: "blob",
            });
        });

        // Create a download using the blob data
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${brand}-${month}-${year}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        return { success: true };
    } catch (error) {
        console.error("Export function error:", error);
        toast.error("Error downloading CSV. Please try again.");
        return { data: { error } };
    }
};

import React, { useState, useEffect } from "react";
import { getExportCsv, getMonthlyReport } from "../services/get";
import { toast } from "react-toastify";

const MonthlyDashboard = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [exportLoading, setExportLoading] = useState({});
    const [selectedExportBrand, setSelectedExportBrand] = useState("");

    const calculatePreviousMonth = () => {
        //! Note: backend API requests are using a 1-12 month system where as JS uses 0-11.
        //! We subtract a month to match the pre-made 1-12 month backend API
        const date = new Date();
        // For January (0), we need to return December (12) of previous year
        if (date.getMonth() === 0) {
            return {
                month: 12,
                year: date.getFullYear() - 1,
            };
        }
        // For all other months, return the current month as 1-based index
        return {
            month: date.getMonth(), // This returns previous month in 1-based system
            year: date.getFullYear(),
        };
    };

    const prevMonth = calculatePreviousMonth();

    const [selectedMonth, setSelectedMonth] = useState(prevMonth.month);
    const [selectedYear, setSelectedYear] = useState(prevMonth.year);

    // Fetch data when month or year changes
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await getMonthlyReport(selectedMonth, selectedYear);

                if (response.error) {
                    console.error("Error in response:", response.error);
                    setError(response.error);
                } else if (response.empty) {
                    setData([]);
                    setError("No data available for the selected period.");
                } else if (response.success) {
                    setData(response.success);
                    setError(null);
                } else {
                    console.log("Unexpected response format:", response);
                    setError("Received unexpected response format from API");
                }
            } catch (err) {
                console.error("Exception occurred:", err);
                setError("Failed to fetch data. Please try again later.");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [selectedMonth, selectedYear]);

    // Format platform names for display
    const formatPlatformName = (name) => {
        return name
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
    };

    // Helper function to determine color based on NPS score
    const getNpsColorClass = (score) => {
        if (isNaN(score) || score === 0) return "";
        if (score >= 50) return "score-high";
        if (score >= 0) return "score-medium";
        return "score-low";
    };

    // Update the CSAT color class function to expect values on 0-100 scale
    const getCsatColorClass = (score) => {
        if (isNaN(score) || score === 0) return "";
        if (score >= 80) return "score-high";
        if (score >= 60) return "score-medium";
        return "score-low";
    };

    // Months array for dropdown
    const months = [
        { value: 1, label: "January" },
        { value: 2, label: "February" },
        { value: 3, label: "March" },
        { value: 4, label: "April" },
        { value: 5, label: "May" },
        { value: 6, label: "June" },
        { value: 7, label: "July" },
        { value: 8, label: "August" },
        { value: 9, label: "September" },
        { value: 10, label: "October" },
        { value: 11, label: "November" },
        { value: 12, label: "December" },
    ];

    // Generate years for dropdown (current year and 4 years back)
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 5 }, (_, i) => currentYear - i);

    // Calculate summary metrics
    const calculateSummaryMetrics = () => {
        if (!data || data.length === 0) {
            return {
                averageNps: "N/A",
                averageCsat: "N/A",
                totalPromoters: "N/A",
                totalDetractors: "N/A",
            };
        }

        let totalNps = 0;
        let totalCsat = 0;
        let totalPromoters = 0;
        let totalDetractors = 0;
        let platformCount = 0;

        data.forEach((item) => {
            const platform = Object.keys(item)[0];
            const platformData = item[platform];

            // Only count platforms with data
            if (platformData.count > 0) {
                totalNps += platformData.NPS.total;
                totalCsat += platformData.CSAT.average;
                totalPromoters += platformData.NPS.counts.pos;
                totalDetractors += platformData.NPS.counts.neg;
                platformCount++;
            }
        });

        return {
            averageNps: platformCount > 0 ? (totalNps / platformCount).toFixed(1) : "N/A",
            averageCsat: platformCount > 0 ? ((totalCsat / platformCount) * 1).toFixed(1) : "N/A",
            totalPromoters: totalPromoters,
            totalDetractors: totalDetractors,
        };
    };

    const handleExportCsv = async (brand) => {
        try {
            // Set loading state for this specific brand
            setExportLoading((prev) => ({ ...prev, [brand]: true }));

            // Call the export function
            await getExportCsv(brand, selectedMonth, selectedYear);

            // Show success message
            toast.success(`Exporting CSV for ${formatPlatformName(brand)}`);
        } catch (error) {
            console.error("Export error caught in handler:", error);
            toast.error(`Failed to export data for ${formatPlatformName(brand)}`);
        } finally {
            // Clear loading state
            setExportLoading((prev) => ({ ...prev, [brand]: false }));
        }
    };

    const { averageNps, averageCsat, totalPromoters, totalDetractors } = calculateSummaryMetrics();

    if (loading) {
        return <div className="loading-spinner"></div>;
    }

    return (
        <div className="reports-container">
            <h1 className="page-title">Customer Satisfaction Reports</h1>

            {/* Date filters */}
            <div className="filter-section">
                <div className="filter-item">
                    <button
                        className="pagination-button"
                        onClick={() => {
                            // Logic to go to previous month
                            let newMonth = selectedMonth - 1;
                            let newYear = selectedYear;
                            if (newMonth < 1) {
                                newMonth = 12;
                                newYear -= 1;
                            }
                            setSelectedMonth(newMonth);
                            setSelectedYear(newYear);
                        }}
                    >
                        &lt; Prev
                    </button>

                    <select id="month-select" value={selectedMonth} onChange={(e) => setSelectedMonth(parseInt(e.target.value))} className="select-input">
                        {months.map((month) => (
                            <option key={month.value} value={month.value}>
                                {month.label}
                            </option>
                        ))}
                    </select>

                    <button
                        className="pagination-button"
                        onClick={() => {
                            // Logic to go to next month
                            let newMonth = selectedMonth + 1;
                            let newYear = selectedYear;
                            if (newMonth > 12) {
                                newMonth = 1;
                                newYear += 1;
                            }
                            setSelectedMonth(newMonth);
                            setSelectedYear(newYear);
                        }}
                    >
                        Next &gt;
                    </button>
                    <div className="filter-item">
                        <select id="year-select" value={selectedYear} onChange={(e) => setSelectedYear(parseInt(e.target.value))} className="select-input">
                            {years.map((year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="filter-item export-controls">
                    <select id="brand-select" value={selectedExportBrand} onChange={(e) => setSelectedExportBrand(e.target.value)} className="select-input">
                        <option value="">Select a brand to export</option>
                        {data
                            .map((item) => {
                                const platform = Object.keys(item)[0];
                                const platformData = item[platform];

                                // Only include platforms with data
                                if (platformData.count > 0) {
                                    return (
                                        <option key={platform} value={platform}>
                                            {formatPlatformName(platform)}
                                        </option>
                                    );
                                }
                                return null;
                            })
                            .filter(Boolean)}
                    </select>

                    <button
                        className="export-button csv-button"
                        onClick={() => {
                            if (selectedExportBrand) {
                                handleExportCsv(selectedExportBrand);
                            } else {
                                toast.warning("Please select a brand to export");
                            }
                        }}
                        disabled={!selectedExportBrand || exportLoading[selectedExportBrand]}
                    >
                        <span className="export-icon">⬇</span>
                        <span>{exportLoading[selectedExportBrand] ? "Exporting..." : "Export CSV"}</span>
                    </button>
                </div>
            </div>

            {/* Display error message if any */}
            {error && <div className="error-message">{error}</div>}

            {/* Stats cards */}
            {!error && (
                <div className="stats-container">
                    <div className="stat-card">
                        <h3 className="stat-title">Average NPS Score</h3>
                        <p className={`stat-value ${getNpsColorClass(parseFloat(averageNps))}`}>{averageNps}</p>
                    </div>
                    <div className="stat-card">
                        <h3 className="stat-title">Average CSAT Score</h3>
                        <p className={`stat-value ${getCsatColorClass(parseFloat(averageCsat))}`}>{averageCsat}%</p>
                    </div>
                    <div className="stat-card">
                        <h3 className="stat-title">Total Promoters</h3>
                        <p className="stat-value promoters">{totalPromoters}</p>
                    </div>
                    <div className="stat-card">
                        <h3 className="stat-title">Total Detractors</h3>
                        <p className="stat-value detractors">{totalDetractors}</p>
                    </div>
                </div>
            )}

            {/* Table */}
            {!error && data.length > 0 && (
                <div className="table-container">
                    <table className="data-table">
                        <thead>
                            <tr>
                                <th>Platform</th>
                                <th>NPS Score</th>
                                <th>CSAT Score</th>
                                <th>Promoters</th>
                                <th>Detractors</th>
                                <th>Total Responses</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => {
                                const platform = Object.keys(item)[0];
                                const platformData = item[platform];

                                // Show all platforms, including those with no data
                                return (
                                    <tr key={index} className={index % 2 === 0 ? "row-even" : "row-odd"}>
                                        <td className="platform-name">{formatPlatformName(platform)}</td>
                                        <td className={`nps-score ${getNpsColorClass(platformData.NPS.total)}`}>{platformData.NPS.total || 0}</td>
                                        <td>
                                            <div className={`csat-score ${getCsatColorClass(platformData.CSAT.average)}`}>{platformData.CSAT.average}%</div>
                                            <div className="progress-container">
                                                <div className="progress-bar" style={{ width: `${platformData.CSAT.average}%` }}></div>
                                            </div>
                                        </td>
                                        <td className="promoters-cell">
                                            {platformData.NPS.counts.pos || 0} ({platformData.NPS.promoters || 0}%)
                                        </td>
                                        <td className="detractors-cell">
                                            {platformData.NPS.counts.neg || 0} ({platformData.NPS.detractors || 0}%)
                                        </td>
                                        <td className="count-cell">{platformData.count || 0}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default MonthlyDashboard;
